var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"test-paper"},[(
        (_vm.question.sat_sat_subject.sat_subject.name === 'reading' || _vm.question.sat_sat_subject.sat_subject.name === 'english' ||
          _vm.question.sat_sat_subject.sat_subject.name === 'writing') &&
          _vm.question.type === 'default'
      )?_c('MultipleChoiceWithPassage_M',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction?_vm.question.sat_passage.introduction.intro : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.activities}}):_vm._e(),(
        (_vm.question.sat_sat_subject.sat_subject.name === 'math' ||
          _vm.question.sat_sat_subject.sat_subject.name === 'math calculator') &&
          _vm.question.type === 'default'
      )?_c('MultipleChoice_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):_vm._e(),(_vm.question.type === 'math')?_c('Math_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }